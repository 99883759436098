import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { BrowserRouter as Router } from 'react-router-dom';

const Header = lazy(() => import('./components/Header'));
const Calculator = lazy(() => import('./components/Calculator'));
const ProfileHeader = lazy(() => import('./components/ProfileHeader'));
const Footer = lazy(() => import('./components/Footer'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#282828',
    },
  },
  typography: {
    fontFamily: 'Golos, Arial, sans-serif',
    h1: {
      fontSize: '40px',
      fontWeight: 'black',
      maxWidth: '900px',
      margin: '0 auto',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: 1.4,
      maxWidth: '780px',
      color: '#787A7C',
      margin: '2px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '58px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '14px',
        },
      },
    },
  },
});

function App() {
  const siteUrl = 'https://zameru.ru'; 
  const title = 'Калькулятор стоимости ремонта - Честный расчет';
  const description = 'Рассчитайте точную стоимость ремонта онлайн. Экономьте до 30% на ремонте квартиры с нашим калькулятором.';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="калькулятор ремонта, стоимость ремонта, расчет ремонта квартиры, капитальный ремонт, стоимость ремонта квартиры, ремонт под ключ стоимость, стоимость капитального ремонта, дизайн проект" />
        <meta name="robots" content="index, follow" />
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${siteUrl}/og-image.jpg`} />
        <meta property="og:url" content={siteUrl} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${siteUrl}/twitter-image.jpg`} />
        
        <meta name="author" content="Честный ремонт" />
        <link rel="canonical" href={siteUrl} />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "Калькулятор стоимости ремонта",
              "description": "${description}",
              "url": "${siteUrl}",
              "applicationCategory": "UtilitiesApplication",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "RUB"
              },
              "creator": {
                "@type": "Organization",
                "name": "Честный ремонт"
              }
            }
          `}
        </script>
      </Helmet>
      
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Suspense fallback={<div>Загрузка...</div>}>
            <ProfileHeader />
            <Container maxWidth={false} sx={{ maxWidth: '900px', flexGrow: 1 }}>
              <Header />
              <Box sx={{ bgcolor: '#F3F3F3', padding: 3, borderRadius: 2, marginBottom: '20px' }}>
                <Calculator />
              </Box>
            </Container>
            <Footer />
          </Suspense>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
